/* eslint-disable react-hooks/exhaustive-deps */
import AboutUs from '@/components/AboutUs';
import {
  DatePickerCustom,
  TimePickerCustom,
} from '@/components/DateTimeCustom';
import ErrorSubmitBar from '@/components/ErrorSubmitBar';
import InputAutocompleteClass from '@/components/InputAutocompleteClass';
import InputCustom from '@/components/InputCustom';
import MapDialog from '@/components/MapDialog';
import PaymentBar from '@/components/PaymentBar';
import PreLoading from '@/components/PreLoading';
import {
  CallCallingIcon,
  DirectUpIcon,
  LocationIcon,
  TicketDiscountIcon,
  UserIcon,
  UserSquareIcon,
} from '@/components/SvgIcons';
import useBooking from '@/hooks/useBooking';
import { bookingService } from '@/services/bookingService';
import useStoreApp from '@/store';
import {
  BookingType,
  MessengerType,
  OrderType,
  SourceType,
} from '@/types/consistency';
import { IBookingForm, IDialCode } from '@/types/interface';
import { bookingScheme } from '@/types/schema';
import { formatNumber } from '@/utils/handleFormatNumber';
import { yupResolver } from '@hookform/resolvers/yup';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import parsePhoneNumber from 'libphonenumber-js';
import moment, { Moment } from 'moment';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  BookingContainer,
  BookingDateTime,
  BookingForm,
  BookingNumberOfPeople,
  BookingPersonalInformation,
  BookingTripInformation,
  StyledSelectDialCode,
} from './style';

const formatDate = 'DD/MM/YYYY';
const formatTime = 'HH:mm';

export default function BookingByCustomer() {
  const { t } = useTranslation(['validate', 'common', 'error']);
  const router = useRouter();
  const { languageId, dialCodes } = useStoreApp(state => state.globalSlice);
  const [typeMap, setTypeMap] = useState<'' | 'pickUp' | 'dropOff'>('');
  const [pickUpMapValue, setPickUpMapValue] = useState<string | null>(
    router.query.address as string | null,
  );
  const [dropOffMapValue, setDropOffMapValue] = useState<string | null>(null);
  const [collaboratorId] = useState<string | null>(
    router.query.collaboratorId as string | null,
  );

  const [promotionName] = useState<string | null>(
    router.query.promotionName as string | null,
  );

  const schema = useMemo(() => yup.object().shape(bookingScheme), []);

  const {
    trigger,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IBookingForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    pickUpData,
    setPickUpData,
    dropOffData,
    setDropOffData,
    promotion,
    setPromotion,
    errorPromotion,
    setErrorPromotion,
    distanceInformation,
    isChangedTimer,
    setIsChangedTimer,
    date,
    setDate,
    dateErrMsg,
    time,
    setTime,
    timeErrMsg,
    successBooking,
    bookingErrMsg,
    errorPrice,
    bookingDone,
    loading,
    realMoney,
    isCurrentDate,
    setDateTimeErrMsg,
    handlePromotion,
    handlePhoneNumber,
    doSubmit,
    checkPickUpAndDropOff,
    handleDistanceInformation,
    DURATION_WAITING_BOOKING_TAXI,
  } = useBooking(bookingService);

  useEffect(() => {
    if (router.query.address && router.query.lat && router.query.lng) {
      setPickUpData({
        pickUp: JSON.stringify({
          lat: Number(router.query.lat),
          lng: Number(router.query.lng),
        }),
        pickUpAddress: router.query.address as string,
      });
    }
  }, []);

  useEffect(() => {
    if (promotionName !== undefined && promotionName !== null && promotionName !== '' && collaboratorId !== null && collaboratorId !== '') {
      handlePromotion(promotionName, collaboratorId);
    }
  }, [promotionName, collaboratorId]);

  const tel = useMemo(() => {
    if (watch('phone') && watch('dialCode')) {
      return parsePhoneNumber(watch('phone'), watch('dialCode'))?.number;
    }
    return undefined;
  }, [watch('phone'), watch('dialCode')]);

  const countryCode = useMemo(() => {
    if (dialCodes.length <= 0) return undefined;
    return dialCodes.find((e: IDialCode) => e.code === watch('dialCode'));
  }, [watch('dialCode'), dialCodes.length]);

  const onSubmit = useCallback(
    (formData: IBookingForm) => {
      // handle time from time, date inputs and check validation
      if (dateErrMsg || timeErrMsg) return;

      if (Boolean(errorPromotion)) return;

      if (Boolean(errorPrice)) return;

      if (!pickUpData || !dropOffData) return;

      const parseTime = time.format(formatTime);
      const parseDate = date.format('YYYY-MM-DD');
      let type = OrderType.Now.code;

      // Create datetime strings in a consistent format
      const selectedDateTime = moment(`${parseDate} ${parseTime}`, 'YYYY-MM-DD HH:mm');
      const currentDateTime = moment();
      const timeDifferenceMinutes = selectedDateTime.diff(currentDateTime, 'minutes');

      let scheduleDate: Date = new Date(`${parseDate}T${parseTime}:00`);

      if (isChangedTimer && timeDifferenceMinutes >= DURATION_WAITING_BOOKING_TAXI) {
        type = OrderType.Timer.code;
      } else {
        scheduleDate = new Date();
      }

      const isMultiETaxi = Number(formData.adults) > 8;
      // ||
      // Number(formData.adults) + Number(formData.children) / 2 > 9;

      let sourceType = SourceType.Customer.code;
      let bookingType = BookingType.WebCustomer.code;

      if (collaboratorId !== null && collaboratorId !== '') {
        sourceType = SourceType.Partner.code;
        bookingType = BookingType.PartnerQR.code;
      }

      let body = {
        isPaymentMerge: isMultiETaxi ? true : false,
        dialCode: countryCode?.dialCode,
        phone: parsePhoneNumber(formData.phone, formData.dialCode)
          ?.nationalNumber,
        passengerName: formData.fullName,
        passengerNormal: Number(formData.adults),
        // passengerHalf: Number(formData.children) ?? 0,
        distanceKm: distanceInformation.km,
        ...pickUpData,
        ...dropOffData,
        price: distanceInformation.price,
        money: realMoney,
        orderType: type,
        scheduleDate,
        description: formData.description,
        languageId,
        messengerType: MessengerType.Sms.code,
        bookingType: bookingType,
        sourceType: sourceType,
      };

      if (promotion !== null) {
        const {
          promotionName,
          promotionPercent,
          promotionPrice,
          ...otherPromotion
        } = promotion;

        body = { ...body, ...otherPromotion };
      }

      doSubmit(body, collaboratorId);
    },
    [
      date,
      dateErrMsg,
      distanceInformation.km,
      distanceInformation.price,
      dropOffData,
      errorPrice,
      errorPromotion,
      isChangedTimer,
      languageId,
      pickUpData,
      promotion,
      realMoney,
      time,
      timeErrMsg,
    ],
  );

  useEffect(() => {
    checkPickUpAndDropOff(setValue, clearErrors);
  }, [dropOffData, pickUpData]);

  useEffect(() => {
    handleDistanceInformation(
      Number(watch('adults')),
      Number(watch('children')),
    );
  }, [distanceInformation.km, watch('adults'), watch('children')]);

  useEffect(() => {
    if (successBooking) {
      if (bookingDone.length > 1) {
        router.push(`/public-history/${tel}`);
      } else router.push(`/booking/${bookingDone?.codeTracking}`);
    }
  }, [successBooking]);

  return (
    <>
      <BookingContainer>
        <Grid container columnSpacing={2}>
          <Grid
            item
            md={12}
            lg={4}
            sx={{
              pt: {
                xs: '24px',
                sm: '84px',
              },
            }}
          >
            <AboutUs />
          </Grid>
          <Grid
            item
            md={12}
            lg={8}
            sx={{
              pt: {
                xs: '24px',
                sm: '84px',
              },
            }}
          >
            <BookingForm onSubmit={handleSubmit(onSubmit)}>
              <Typography component="h2">{t('common:eTaxiBooking')}</Typography>
              <Grid
                container
                columnSpacing={{ xs: 0, md: 3.5 }}
                rowSpacing={{ xs: 6, md: 0 }}
              >
                <Grid item xs={12} md={6}>
                  <BookingTripInformation>
                    <Typography component="h3">
                      {t('common:tripInfo')}
                    </Typography>
                    <Box className="wrapper-input-button">
                      <InputAutocompleteClass
                        isInitDefaultLocation={!router.query.address}
                        idText="pickUp"
                        icon={<LocationIcon />}
                        placeholder={t('common:pickUp')! + ' *'}
                        setOutput={setPickUpData}
                        setValue={(val: string, options?: any) =>
                          setValue('pickUp', val, { ...options })
                        }
                        mapValue={pickUpMapValue}
                        register={register('pickUp')}
                        error={Boolean(errors.pickUp)}
                        helperText={
                          errors.pickUp?.message &&
                          t(`validate:${errors.pickUp?.message}`)
                        }
                      />
                      <IconButton onClick={() => setTypeMap('pickUp')}>
                        <MapIcon />
                      </IconButton>
                    </Box>

                    <Box className="wrapper-input-button">
                      <InputAutocompleteClass
                        idText="dropOff"
                        icon={<DirectUpIcon />}
                        placeholder={t('common:dropOff')! + ' *'}
                        setOutput={setDropOffData}
                        setValue={(val: string, options?: any) =>
                          setValue('dropOff', val, { ...options })
                        }
                        mapValue={dropOffMapValue}
                        register={register('dropOff')}
                        error={Boolean(errors.dropOff)}
                        helperText={
                          errors.dropOff?.message &&
                          t(`validate:${errors.dropOff?.message}`)
                        }
                      />

                      <IconButton onClick={() => setTypeMap('dropOff')}>
                        <MapIcon />
                      </IconButton>
                    </Box>

                    <BookingNumberOfPeople>
                      <InputCustom
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UserIcon />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        autoComplete="off"
                        placeholder={t('common:adultGreaterThan2')! + ' *'}
                        register={register('adults')}
                        error={Boolean(errors.adults)}
                        helperText={
                          errors.adults?.message &&
                          t(`validate:${errors.adults?.message}`)
                        }
                      />

                      {/* <InputCustom
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UserIcon />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        autoComplete="off"
                        placeholder={t('common:children')!}
                        register={register('children')}
                        error={Boolean(errors.children)}
                        helperText={
                          errors.children?.message &&
                          t(`validate:${errors.children?.message}`)
                        }
                      /> */}
                    </BookingNumberOfPeople>

                    <InputCustom
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TicketDiscountIcon />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      placeholder={t('common:promotionCode')!}
                      register={register('promotionCode')}
                      defaultValue={promotionName}
                      disabled={promotionName !== undefined && promotionName !== null && promotionName !== ''}
                      onBlur={(event: any) => {
                        if (event.target.value.trim()) {
                          handlePromotion(event.target.value, collaboratorId);
                        } else {
                          setPromotion(null);
                          setErrorPromotion('');
                        }
                      }}
                      error={Boolean(errorPromotion)}
                      helperText={
                        !!promotion
                          ? `${t('common:discount')}: ${promotion?.promotionPercent
                            ? distanceInformation?.price !== 0
                              ? formatNumber(promotion?.discount) + ' VNĐ'
                              : promotion.promotionPercent + '%'
                            : formatNumber(promotion?.promotionPrice) +
                            ' VNĐ'
                          }`
                          : errorPromotion ?? undefined
                      }
                    />

                    <PaymentBar
                      textKm={t('common:distance')}
                      textMoney={t('common:price')}
                      km={distanceInformation.km}
                      money={realMoney}
                    />
                  </BookingTripInformation>
                </Grid>

                <Grid item xs={12} md={6}>
                  <BookingPersonalInformation>
                    <Typography component="h3">
                      {t('common:passengerInfo')}
                    </Typography>
                    <Box className="name-phone">
                      <InputCustom
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UserSquareIcon />
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="off"
                        placeholder={t('common:fullName')! + ' *'}
                        register={register('fullName')}
                        error={Boolean(errors.fullName)}
                        helperText={
                          errors.fullName?.message &&
                          t(`validate:${errors.fullName?.message}`)
                        }
                      />

                      <Box className="wrapper-select-input">
                        <StyledSelectDialCode
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '300px',
                              },
                            },
                          }}
                          defaultValue="VN"
                          {...register('dialCode')}
                          onChange={(e: any) => {
                            register('dialCode').onChange(e);
                            if (watch('phone')) {
                              trigger(['phone']);
                              handlePhoneNumber(
                                dialCodes.find(
                                  (item: IDialCode) =>
                                    item.code === (e.target.value as string),
                                )!.dialCode,
                                watch('phone'),
                                setValue,
                                watch('fullName'),
                              );
                            }
                          }}
                        >
                          {dialCodes.map((e: IDialCode, i: number) => (
                            <MenuItem value={e.code} key={i}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </StyledSelectDialCode>
                        <InputCustom
                          type="tel"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CallCallingIcon />
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                          autoComplete="off"
                          placeholder={t('common:phone')! + ' *'}
                          register={register('phone')}
                          error={Boolean(errors.phone)}
                          helperText={
                            errors.phone?.message &&
                            t(`validate:${errors.phone?.message}`)
                          }
                          onBlur={(event: any) => {
                            if (!Boolean(errors.phone) && event.target.value) {
                              handlePhoneNumber(
                                countryCode!.dialCode,
                                event.target.value,
                                setValue,
                                watch('fullName'),
                              );
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </BookingPersonalInformation>

                  <BookingDateTime>
                    <Typography component="h3">{t('common:timer')}</Typography>
                    <Box className="date-time">
                      <DatePickerCustom
                        format={formatDate}
                        value={date}
                        onChange={(val: any, ctx: any) => {
                          setIsChangedTimer(true);
                          setDateTimeErrMsg(ctx.validationError, 'date');
                          setDate(val);
                        }}
                        disablePast
                        helperText={dateErrMsg && t(`validate:${dateErrMsg}`)!}
                      />
                      <TimePickerCustom
                        ampm={false}
                        ampmInClock={false}
                        format={formatTime}
                        value={time}
                        onChange={(val: any, ctx: any) => {
                          setIsChangedTimer(true);
                          setDateTimeErrMsg(ctx.validationError, 'time');
                          setTime(val);
                        }}
                        disablePast={isCurrentDate}
                        error={!!timeErrMsg}
                        //helperText={timeErrMsg && t(`validate:${timeErrMsg}`)!}
                        helperText={timeErrMsg}
                      />
                    </Box>
                    <TextField
                      autoComplete="off"
                      placeholder={t('common:description')!}
                      {...register('description')}
                      multiline
                      rows={3}
                      sx={{
                        textarea: {
                          height: '82px !important',
                        },
                      }}
                    />
                  </BookingDateTime>
                </Grid>
                <Grid item xs={12}>
                  <ErrorSubmitBar
                    phone={tel}
                    error={Boolean(bookingErrMsg) || Boolean(errorPrice)}
                    errorText={bookingErrMsg || errorPrice}
                    buttonText={t('common:booking')}
                    sx={{ marginTop: '24px' }}
                  />
                </Grid>
              </Grid>
            </BookingForm>
          </Grid>
        </Grid>
      </BookingContainer>

      {loading && <PreLoading backgroundColor="default" />}

      <MapDialog
        type={typeMap}
        onClose={() => setTypeMap('')}
        setMapValue={value => {
          if (typeMap === 'pickUp') setPickUpMapValue(value);
          if (typeMap === 'dropOff') setDropOffMapValue(value);
        }}
        pickUpData={pickUpData}
        setPickUpData={data => {
          setPickUpData(data);
        }}
        dropOffData={dropOffData}
        setDropOffData={data => {
          setDropOffData(data);
        }}
      />
    </>
  );
}
